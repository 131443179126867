<template>
  <!-- 合作客户-编辑-客户资料 -->
  <div>
    <div class="flexBox" style=" margin-top: 24px;">
      <div style="display: flex;align-items: center;" class="ImportTop" v-if="!type">
        <el-button style="width: 320px;" @click="fileBtn()" type="primary" class="ChooseFile">请上传附件</el-button>
        <el-dialog title="上传附件" :visible.sync="dialogTableVisible">
          <div style="display: flex;">
            <el-select
              style="margin-right: 20px; width: 224px;"
              clearable
              v-model="listParams.tag"
              placeholder="请选择上传基本资料类型"
            >
              <el-option
                v-for="item in typeList"
                :key="item.type"
                :label="item.label"
                :value="item.type"
              ></el-option>
            </el-select>
            <el-upload
              class="upload-demo"
              ref="upload"
              action="string"
              :http-request="httpRequest"
              :show-file-list="true"
              :before-upload="beforeAvatarUpload"
              :file-list="fileList"
              :auto-upload="false"
            >
              <el-button type="primary" class="ChooseFile">请选择文件上传（可多选）</el-button>
              <div
                style="color:#A6ABC7;font-size: 12px;margin: 20px 0;margin-left: -370px;"
              >上传txt/jpg/png/doc/xls/pdf/zip文件，单个文件不超过10mb</div>
            </el-upload>
          </div>
          <div style="width: 100%;text-align: center;">
            <el-button
              style="margin: 24px 0;"
              type="primary"
              class="submitUpload"
              @click="onSubmit()"
            >确定</el-button>
          </div>
        </el-dialog>

        <span>（上传文件格式支持pdf，jpg，word）</span>
      </div>
      <div style="display: flex;">
        <el-select
          style="width: 224px;margin-right: 20px;"
          v-model="listParams.type"
          placeholder="请选择客户资料类型"
          @change="selectClick"
        >
          <el-option
            v-for="item in selectList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-select
          clearable
          v-if="this.zltype == '基本资料'"
          style="width: 224px;margin-right: 20px;"
          v-model="listParams.tag"
          placeholder="请选择客户资料基本类型分类"
          @change="selectClick"
        >
          <el-option
            v-for="item in typeList"
            :key="item.label"
            :label="item.label"
            :value="item.type"
          ></el-option>
        </el-select>
        <el-input
          clearable
          style="width: 320px;margin-right: 20px;"
          class="import"
          v-model="listParams.keywords"
          placeholder="请输入文件名称"
          @keyup.enter.native="handleSearch()"
        ></el-input>
        <el-button class="search" @click="handleSearch()">查询</el-button>
      </div>
    </div>
    <el-table
      v-loading="loading"
      element-loading-text="正在加载中···"
      :data="dataList.data"
      stripe
      style="width: 100%;margin-top: 10px;"
    >
      <el-table-column
        prop="name"
        label="文件名称"
        style="cursor: pointer;"
        width="550"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        v-if="labelTitle"
        :prop="fileName"
        :label="labelTitle"
        width="550"
        show-overflow-tooltip
      >
        <template slot-scope="scope"></template>
      </el-table-column>
      <el-table-column label="资料类型">
        <span>{{ zltype }}</span>
      </el-table-column>
      <el-table-column prop="tag" label="分类" v-if="zltype == '基本资料'"></el-table-column>
      <el-table-column prop="create_time" label="上传/更新时间 "></el-table-column>
      <el-table-column label="操作 ">
        <template slot-scope="scope">
          <el-button class="edit" size="mini" @click="handleDown(scope.row.url)">下载</el-button>
          <el-button
            :disabled="zltype != '基本资料'"
            v-if="!type"
            class="del"
            size="mini"
            @click="handleDel(scope.row.id)"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="block" v-if="dataList.total > 10">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="dataList.page"
        :page-sizes="[2, 10, 20, 40]"
        :page-size="dataList.limit"
        layout="sizes, prev, pager, next,total,jumper"
        :total="dataList.total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import typeList from '../../utils/selectList.json'
import axios from 'axios'
import selectList from '../../utils/selectList.json'
import down from '../../components/global.vue'
export default {
  props: ['com_id', 'type'],
  data () {
    return {
      typeList: typeList.customers_upload_type,
      dialogTableVisible: false,
      fileName: '',
      zltype: '基本资料',
      downUrl: down.userSite,
      labelTitle: '',
      selectList: selectList.customers_type,
      loading: false,
      dataList: {},
      listParams: {
        page: 1,
        limit: 10,
        keywords: '',
        com_id: this.com_id,
        type: '1',
        tag: '',
      },
      fileList: [],
    }
  },
  created () {
    // this.getList()
  },
  methods: {
    fileBtn () {
      this.listParams.tag = ''
      this.fileList = []
      setTimeout(() => {
        this.dialogTableVisible = true
      });
    },
    onSubmit () {
      this.$refs.upload.submit();
    },
    getList () {
      this.loading = true
      this.axios.get('/api/company/company_material', { params: { ...this.listParams } }).then((res) => {
        if (res.data) {
          this.dataList = res.data
        } else {
          this.dataList = []
        }
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    selectClick (val) {
      if (val == 1) {
        this.labelTitle = ''
        this.zltype = '基本资料'
        this.fileName = ''
        this.getList()
      }
      if (val == 2) {
        this.labelTitle = '合同名称'
        this.zltype = '合同附件'
        this.fileName = 'contract_name'
        this.getList()
      }
      if (val == 3) {
        this.labelTitle = '专利名称'
        this.zltype = '专利附件'
        this.fileName = 'patent_name'
        this.getList()
      }
      if (val == 4) {
        this.labelTitle = '软著名称'
        this.zltype = '软著附件'
        this.fileName = 'soft_name'
        this.getList()
      }
      if (val == 5) {
        this.labelTitle = '查新检测名称'
        this.zltype = '查新检测附件'
        this.fileName = 'novelty_name'
        this.getList()
      }
    },
    handleSearch () {
      this.getList()
    },
    handleDown (url) {
      if (url) {
        window.open(this.downUrl + url, '_blank')
      }
    },
    handleDel (id) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.axios.delete('/api/company/delete_company_material', { params: { id: id } }).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.getList()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    handleSizeChange (val) {
      this.getList(this.listParams.limit = val)
    },
    handleCurrentChange (val) {
      this.getList(this.listParams.page = val)
    },
    httpRequest (param) {
      let file = param.file;
      const form = new FormData();
      form.append("file", file);
      form.append("com_id", this.com_id);
      form.append("tag", this.listParams.tag);
      if (this.listParams.tag) {
        this.upload("/api/company/upload_material", form).then((res) => {
          if (res.code == 1) {
            this.$message({
              type: "success",
              message: "上传附件成功",
            });
            this.getList()
            this.dialogTableVisible = false
          } else {
            this.$message({
              type: "error",
              message: "上传附件失败",
            });
          }
        });
      } else {
        this.$message.warning('请选择上传基本资料类型')
      }
    },
    upload (url, params) {
      return axios
        .post(url, params, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          return new Promise((resolve, reject) => {
            resolve(res);
          });
        })
        .catch((err) => {
          console.log("err:", err);
        });
    },
    // 上传文件前的过滤
    beforeAvatarUpload (file) {
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        this.$message.error("上传文件大小不能超过 20MB!");
      }
      return isLt20M;
    },
  }
}
</script>

<style scoped>
.ChooseFile {
  width: 224px;
}
.ImportTop span {
  color: #a6abc7;
  margin-left: 20px;
}
.flexBox {
  display: flex;
  justify-content: space-between;
}
div/deep/.el-upload-list {
  margin-left: -240px;
}
</style>