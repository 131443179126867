<template>
  <div>
    <!-- 合作客户-编辑-项目列表 -->
    <div class="topUsers" style="margin-top: 24px;">
      <div>
        <el-input
          class="import"
          v-model="listParams.keywords"
          placeholder="请输入企业名称关键字"
          @keyup.enter.native="handleSearch()"
        ></el-input>
        <el-button class="search" @click="handleSearch()">查询</el-button>
      </div>
    </div>
    <el-table :data="dataList.data" stripe style="width: 100%">
      <el-table-column label="项目名称" width="150">
        <template slot-scope="scope">
          <el-link
            @click="titleChange(scope.row.contract_project_id)"
            :underline="false"
          >{{ scope.row.title }}</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="product_type" label="项目类型" width="150"></el-table-column>
      <el-table-column prop="code" label="项目编号"></el-table-column>
      <el-table-column prop="contract_name" label="合同名称" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-link
            @click="nameChange(scope.row.contract_id)"
            :underline="false"
          >{{ scope.row.contract_name }}</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态 " width="100">
        <template slot-scope="scope">
          <div v-for="item in statusList" :key="item.status">
            <span
              v-if="item.status == scope.row.status"
              :style="{color:`${item.color}`}"
            >{{item.text }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间 "></el-table-column>
      <el-table-column label="操作 ">
        <template slot-scope="scope">
          <el-button
            class="edit"
            size="mini"
            @click="handleLook(scope.row.product_id)"
            v-if="scope.row.status == 3 || scope.row.status == 4"
          >查看</el-button>
          <span size="mini" v-if="scope.row.status != 3 && scope.row.status != 4">-</span>
        </template>
      </el-table-column>
    </el-table>

    <div class="block" v-if="dataList.total > 10">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="dataList.page"
        :page-sizes="[2, 10, 20, 40]"
        :page-size="dataList.limit"
        layout="sizes, prev, pager, next,total,jumper"
        :total="dataList.total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import statusList from '../../utils/selectList.json'
import down from '../../components/global.vue'
export default {
  props: ['com_id'],
  data () {
    return {
      statusList: statusList.customers_type_status,
      downUrl: down.userSite,
      dataList: [{
        title: 1,
      }],
      listParams: {
        page: 1,
        limit: 10,
        com_id: this.com_id,
        keywords: '',
      }
    }
  },
  created () {
    this.getList()
  },
  methods: {
    titleChange (id) {
      this.$router.push({ path: '/contract/con_detail', query: { id: id } })
    },
    nameChange (id) {
      this.$router.push({ path: '/contract/detail', query: { id: id } })
    },
    getList () {
      this.loading = true
      this.axios.get('/api/contract/company_project', { params: { ...this.listParams } }).then((res) => {
        if (res.data) {
          this.dataList = res.data
        } else {
          this.dataList = []
        }
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    handleSearch () {
      this.getList()
    },
    handleLook (product_id) {
      this.$router.push({ path: '/project/process', query: { id: product_id } })
    },
    handleSizeChange (val) {
      this.getList(this.listParams.limit = val)
    },
    handleCurrentChange (val) {
      this.getList(this.listParams.page = val)
    },
  },
}
</script>

<style scoped>
div/deep/.el-link.el-link--default {
  /* color: #606266; */
  font-weight: bold;
}
</style>